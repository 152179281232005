<template>
  <div class="flex items-center justify-center !overflow-visible">
    <fr-page
      class="flex h-screen min-h-[600px] w-full flex-col flex-nowrap justify-center overflow-visible !p-3 md:min-h-0 md:w-[400px] md:min-w-[400px] lg:h-full lg:w-[70vw] lg:min-w-[70vw] lg:flex-row xl:w-[800px] xl:min-w-[800px]"
    >
      <!-- Left side / Top side-->
      <fr-card
        class="relative h-[200px] w-full !rounded-bl-none !rounded-br-none !rounded-tl-lg !rounded-tr-lg !border-b-0 lg:h-[460px] lg:!rounded-bl-lg lg:!rounded-br-none lg:!rounded-tl-lg lg:!rounded-tr-none lg:!border-b-[1px] lg:!border-r-0"
      >
        <fr-image
          :src="`${serverURL}/public/landing-image.jpg`"
          class="h-full w-full rounded-bl-none rounded-tl-lg rounded-tr-lg object-cover lg:rounded-bl-lg lg:rounded-tr-none"
        />

        <div
          class="absolute inset-0 z-10 rounded-bl-none rounded-tl-lg rounded-tr-lg bg-gradient-to-t from-black/90 to-black/10 lg:rounded-bl-lg lg:rounded-tr-none"
        />

        <div
          v-if="!isFetchingDailyQuoteMessage"
          class="absolute bottom-0 z-40 bg-transparent p-3 text-white"
        >
          {{ dailyQuoteMessage }}
        </div>

        <div
          v-else
          class="absolute bottom-0 z-40 flex w-full flex-col gap-2 bg-transparent p-3"
        >
          <q-skeleton
            v-for="i in 2"
            :key="`skeleton-${i}`"
            width="100%"
            class="h-4"
          />

          <q-skeleton width="65%" class="h-4" />
        </div>
      </fr-card>

      <!-- Right side / Bottom side -->
      <fr-card
        class="flex h-full w-full flex-col flex-nowrap !rounded-bl-lg !rounded-br-lg !rounded-tl-none !rounded-tr-none lg:h-[460px] lg:!rounded-bl-none lg:!rounded-tl-none lg:!rounded-tr-lg"
      >
        <fr-card-header
          show-close-button
          transparent
          class="!flex-row !rounded-tl-none !rounded-tr-none !py-0 lg:!rounded-tr-lg"
          :title="$t('login.header')"
          :subtitle="$t('login.subheader')"
        />
        <fr-card-body class="h-full !rounded-bl-lg !rounded-br-lg">
          <TransitionIn>
            <q-form
              v-if="!user?.id && !isLoggingIn && !isVerifying"
              key="login-form"
              :data-index="2"
              class="flex w-full flex-1 flex-col items-center gap-3"
              @submit="login()"
            >
              <div class="flex w-full flex-col gap-3 py-6 md:w-4/5">
                <q-input
                  id="email"
                  v-model.trim="state.email"
                  outlined
                  class="email w-full"
                  :label="$t('login.emailLabel')"
                  :rules="[(val) => !!val || $t('login.emailRequiredLabel')]"
                  lazy-rules="ondemand"
                  type="email"
                  hide-bottom-space
                  required
                  autofocus
                />
                <q-input
                  v-model.trim="state.password"
                  outlined
                  class="password w-full"
                  :label="$t('login.passwordLabel')"
                  :rules="[(val) => !!val || $t('login.passwordRequiredLabel')]"
                  lazy-rules="ondemand"
                  :type="passwordType"
                  hide-bottom-space
                  required
                >
                  <template #append>
                    <q-icon
                      name="visibility"
                      class="cursor-pointer"
                      @click="
                        () =>
                          (passwordType =
                            passwordType === 'password' ? 'text' : 'password')
                      "
                    />
                  </template>
                </q-input>

                <fr-button
                  type="submit"
                  :disabled="isLoggingIn || isVerifying"
                  :loading="isLoggingIn || isVerifying"
                >
                  <q-icon name="login" />
                  {{ $t('login.buttonLabel') }}
                </fr-button>

                <fr-button
                  variant="outline"
                  :disabled="isLoggingIn || isVerifying"
                  @click="
                    () => navigateTo(microsoftLoginUrl, { external: true })
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                  >
                    <rect x="1" y="1" width="9" height="9" fill="#f25022" />
                    <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
                    <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
                    <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
                  </svg>

                  Sign in with Microsoft
                </fr-button>
              </div>
            </q-form>

            <div
              v-else-if="isLoggingIn || isVerifying"
              key="login-loading"
              :data-index="2"
              class="flex w-full flex-1 flex-col items-center justify-center gap-3"
            >
              <q-spinner-gears size="80px" />
              <p>Signing you in...</p>
            </div>

            <div
              v-else
              key="login-welcome"
              :data-index="2"
              class="relative flex w-full flex-1 flex-col items-center justify-center"
            >
              <user-card :user="user" :wrike-user="wrikeUser" />
            </div>
          </TransitionIn>
        </fr-card-body>
      </fr-card>
    </fr-page>
  </div>
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'
import { useServiceDeskStore } from '~/features/service-desk/store'
import UserCard from '~/features/auth/components/user-card.vue'

import factories from '~/factories'

/**
 * Confetti - tsparticles
 */
import { z } from 'zod'
import type { QInputProps } from 'quasar'

const serverURL = process.env.FR_PERMISSIONS_URL
const microsoftLoginUrl = serverURL + '/auth/ad'
const router = useRouter()

const state = reactive<{
  email: string
  emailErorr: boolean
  password: string
  passwordError: boolean
}>({
  email: '',
  emailErorr: false,
  password: '',
  passwordError: false
})

const passwordType = ref<QInputProps['type']>('password')

const emits = defineEmits(['close'])

const loginSchema = z.object({
  email: z.string().email(),
  password: z.string()
})

const authStore = useAuthStore()
const serviceDeskStore = useServiceDeskStore()

// computed
const wrikeUser = computed({
  get: () => serviceDeskStore.wrikeUser,
  set: (value) => (serviceDeskStore.wrikeUser = value)
})

const user = useUser()

const login = async () => {
  const { success } = loginSchema.safeParse({
    email: state.email,
    password: state.password
  })

  if (success) {
    useLogin({
      username: state.email,
      password: state.password
    })
  }
}

const { mutate: useLogin, isPending: isLoggingIn } =
  factories.auth.mutations.useLogin({
    options: {
      onSuccess: () => {
        setTimeout(() => {
          if (user.value?.id) {
            authStore.showLoginModal = false
            emits('close')
          }
        }, 4000)
      },
      onError: (error) => {
        console.log(error)

        notifier({
          message: error.message,
          type: 'error'
        })
      }
    }
  })

const { isSuccess: isSuccessfulVerify, isFetching: isVerifying } =
  factories.auth.queries.useVerifyMe({
    options: {
      enabled: router.currentRoute.value.name === 'verify'
    }
  })

watchEffect(() => {
  if (isSuccessfulVerify.value && router.currentRoute.value.name === 'verify') {
    setTimeout(() => {
      if (user.value?.id) {
        authStore.showLoginModal = false
        router.push('/')
        emits('close')
      }
    }, 4000)
  }
})

/**
 * Daily quote
 */
const { data: dailyQuoteMessage, isFetching: isFetchingDailyQuoteMessage } =
  factories.AI.queries.useGetDailyQuote()
</script>
